import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import "../sass/custom.scss"

const Layout = ({ children, classNames, props }) => (
  <>
    <a className='skip-link screen-reader-text' href='#content'>
      Skip to content
    </a>
    <Header siteTitle={"Ideal Capital Group"} />
    <div id='content' className='site-content'>
      <section id='primary' className='content-area'>
        <main id='main' className='site-main'>
          {children}
        </main>
        {/* #main */}
      </section>
      {/* #primary */}
    </div>
    <div>
      <Footer />
    </div>
  </>
)



Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
